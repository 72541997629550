import React, { Fragment, useEffect, useState } from 'react';
import '../../style/css/StylePc.css';
import Home from './Home';
import Comissions from './Comissions';
import Gallery from './Gallery';
import Reviews from './Reviews'
const Main = () => {
    const [currentTab, setCurrentTab] = useState('Home');
    
    const tabs = ['Home', 'Comissions', 'Reviews', 'Gallery'];

    const Components = {
        'Home': Home,
        'Comissions':Comissions,
        'Gallery': Gallery,
        'Reviews': Reviews
    };
    
    const changeTab = (tab) => {
        setCurrentTab(tab);
    }

    return (
        <Fragment>
            <div id="Home" className='headerPc'>
                <div id="nav" className='navigationPc'>
                    {tabs.map(tab => {
                        return <span key={tab} className="tabPc" style={{fontWeight: currentTab===tab ? 600 : 100}} onClick={()=>changeTab(tab)}>{tab}</span>
                    })}
                </div>
            </div>

            {React.createElement(Components[currentTab])}
            
            <div if="footer" className='footerPc'>
                    <div className='footerLinks'>
                        <div className='linksLeft'>
                            <div style={{display:'flex', gap: '10px'}}><div className='squareIcon'><img className='imgIcon' alt='social icon' src={require('../../img/Home/gmail.png')}></img></div><span>bluebirdiedrawings@gmail.com</span></div>
                            <div style={{display:'flex', gap: '10px'}}><div className='squareIcon'><img className='imgIcon' alt='social icon' style={{height:'125%'}} src={require('../../img/Home/ig.png')}></img></div><span>/BlueBirdieDrawings</span></div>
                        </div>
                        <div className='linksRight'>
                            <div style={{display:'flex', gap: '10px'}}><div className='squareIcon'><img className='imgIcon' alt='social icon' style={{height:'150%'}} src={require('../../img/Home/fb.png')}></img></div><span>/BlueBirdieDrawings</span></div>
                            <div style={{display:'flex', gap: '10px'}}><div className='squareIcon'><img className='imgIcon' alt='social icon' style={{height:'150%'}} src={require('../../img/Home/tt.png')}></img></div><span>/BlueBirdieDrawings</span></div>
                        </div>
                    </div>
                    <span style={{fontSize:'0.7rem', color:'lightgray', margin:'auto 0 10px 0'}}>@2022 Maciej Knapik</span>
            </div>
        </Fragment>
    )
}

export default Main;