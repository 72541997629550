import React, { Fragment, useState, useRef } from 'react';
import ReactDOM from 'react-dom'
import '../../style/css/StylePc.css'
const Pricing = ({sizeIn, sizeCm, text, price, availability=true}) => {

    return (
        <Fragment>
            <div>
            {availability == false ? <div className='unAvailableBox'>Unavailable</div> : null}
                <div className='pricingBox' style={{opacity: availability ? '100%':'10%'}}>
                    <div style={{color:'#143354', fontSize:'1.5rem', fontWeight:'600', paddingTop:'20px'}}>{sizeIn}</div>
                    <div style={{color:'#143354', fontSize:'1rem', marginTop:'-1rem'}}>{sizeCm}</div>
                    <div style={{width:'10vw', textAlign:'center', marginTop:'0.5rem'}}>{text}</div>
                    <div style={{height:'25%', color:'white', display:'flex', alignItems:'center', fontSize:'2rem'}}>{price}</div>
                </div>
            </div>
        </Fragment>
    )
}

export default Pricing;