import React, { Fragment, useState, useRef } from 'react';
import ReactDOM from 'react-dom'
import '../../style/css/StylePc.css'
import Card from './Card';
const review = require('../ReviewsTexts');
const Reviews = () => {

    return (
        <>
            <div id="topCommissions" className='topbarReviews'>
                <div id="comissionsMainText" className='commissionsMainText'>
                    <span style={{fontSize:'2.5rem', fontWeight:'700'}}>Reviews</span>
                    <span style={{fontSize:'1.5rem', marginBottom:'1rem'}}>Kind words from customers</span>
                    <span>Presented testimonials were gathered among my customers located all over the word.
                    </span>
                </div>
            </div>
            <div id="cardHolder" className='cardHolder'>
                <Card title={review.SILVER[0]} technic={review.SILVER[1]} size="1" picture="silver_reviews.jpg" text={review.SILVER[2]} client={review.SILVER[3]}/>
                <Card title={review.KALINO[0]} technic={review.KALINO[1]} size="1" picture="kalino_reviews.jpg" text={review.KALINO[2]} client={review.KALINO[3]}/>
                <Card title={review.RUFUS[0]} technic={review.RUFUS[1]} size="1" picture="rufus_reviews.jpg" text={review.RUFUS[2]} client={review.RUFUS[3]}/>
                <Card title={review.DUKE[0]} technic={review.DUKE[1]} size="1" picture="duke_reviews.jpg" text={review.DUKE[2]} client={review.DUKE[3]}/>
                <Card title={review.RUBY[0]} technic={review.RUBY[1]} size="1" picture="Ruby_reviews.jpg" text={review.RUBY[2]} client={review.RUBY[3]}/>
                <Card title={review.GOOSE[0]} technic={review.GOOSE[1]} size="1" picture="goose_reviews.jpg" text={review.GOOSE[2]} client={review.GOOSE[3]}/>
                <Card title={review.RASPUTIN[0]} technic={review.RASPUTIN[1]} size="1" picture="rasputin_reviews.jpg" text={review.RASPUTIN[2]} client={review.RASPUTIN[3]}/>
                <Card title={review.HORSES[0]} technic={review.HORSES[1]} size="1" picture="horses_reviews.jpg" text={review.HORSES[2]} client={review.HORSES[3]}/>
                <Card title={review.CASH[0]} technic={review.CASH[1]} size="1" picture="cash_reviews.jpg" text={review.CASH[2]} client={review.CASH[3]}/>
                <Card title={review.COCO[0]} technic={review.COCO[1]} size="2" picture="coco_reviews.jpg;pika_and_roki_reviews.jpg;trufo_reviews.jpg" text={review.COCO[2]} client={review.COCO[3]}/>
                <Card title={review.IZZY[0]} technic={review.IZZY[1]} size="1" picture="izzy_reviews.jpg" text={review.IZZY[2]} client={review.IZZY[3]}/>
                <Card title={review.LALI[0]} technic={review.LALI[1]} size="1" picture="lali_reviews.jpg" text={review.LALI[2]} client={review.LALI[3]}/>
                <Card title={review.MIRACLE[0]} technic={review.MIRACLE[1]} size="2" picture="miracle_reviews.jpg;joey_reviews.jpg" text={review.MIRACLE[2]} client={review.MIRACLE[3]}/>
            </div>
        </>
    )
}

export default Reviews;