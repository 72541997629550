import React, { Fragment, useState, useRef } from 'react';
import ReactDOM from 'react-dom'
import '../../style/css/StylePc.css'
const Card = ({ title, technic, size, picture, text, client }) => {
    const received = picture.includes(';') ? picture.split(';') : null;
    const names = title.includes(';') ? title.split(';') : null;
    const technics = technic.includes(';') ? technic.split(';') : null;
    return (
        <>
            <div className={`card${size}`}>
                {size === "1" ? (
                    <div className='cardHolder'>
                        <div className='upperCard'>
                            <div style={{ lineHeight: '2rem' }}>
                                <span style={{ color: '#143354', fontSize: '1.8rem', fontWeight: '700' }}>{title}</span>
                                <br />
                                <span>{technic}</span>
                            </div>
                            <img style={{ width: '250px', borderRadius: '20px' }} src={require(`../../img/Reviews/${picture}`)}></img>
                        </div>

                        <span>{text}</span>

                        <span style={{ margin: '0 0 0 auto', color: '#143354', fontWeight: '600', fontSize: '1.1rem' }}>{client}</span>

                    </div>
                ) : !title.includes('Joey') ? (
                    <div className='cardHolder'>
                        <div className='upperCard'>
                            <div style={{ lineHeight: '2rem' }}>
                                <span style={{ color: '#143354', fontSize: '1.8rem', fontWeight: '700' }}>{title}</span>
                                <br />
                                <span>{technic}</span>
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <img style={{ width: '250px', borderRadius: '20px' }} src={require(`../../img/Reviews/${received[0]}`)}></img>
                                <img style={{ width: '250px', borderRadius: '20px' }} src={require(`../../img/Reviews/${received[1]}`)}></img>
                                <img style={{ width: '250px', borderRadius: '20px' }} src={require(`../../img/Reviews/${received[2]}`)}></img>
                            </div>
                        </div>

                        <span>{text}</span>

                        <span style={{ margin: '0 0 0 auto', color: '#143354', fontWeight: '600', fontSize: '1.1rem' }}>{client}</span>

                    </div>
                ) : (
                    <div className='cardHolder'>
                        <div className='upperCard2'>
                            <div style={{ lineHeight: '2rem', display: 'flex', alignItems: 'center', justifyContent:'space-between', width:'40%' }}>
                                <div>
                                    <span style={{ color: '#143354', fontSize: '1.8rem', fontWeight: '700' }}>{names[0]}</span>
                                    <br />
                                    <span>{technics[0]}</span>
                                </div>
                                <img style={{ width: '250px', borderRadius: '20px' }} src={require(`../../img/Reviews/${received[0]}`)}></img>
                            </div>
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent:'space-evenly', width:'60%' }}>
                                <div>
                                    <span style={{ color: '#143354', fontSize: '1.8rem', fontWeight: '700' }}>{names[1]}</span>
                                    <br />
                                    <span>{technics[1]}</span>
                                </div>
                                <img style={{ width: '250px', borderRadius: '20px' }} src={require(`../../img/Reviews/${received[1]}`)}></img>
                            </div>
                        </div>

                        <span>{text}</span>

                        <span style={{ margin: '0 0 0 auto', color: '#143354', fontWeight: '600', fontSize: '1.1rem' }}>{client}</span>

                    </div>
                )}

            </div>
        </>
    )
}

export default Card;