export const list = () => {
    let arr = `angie_2022.jpg;
astrid_2022.jpg;
bailey_2022.jpg;
barbossa_aubrey_2019.jpg;
bella_2020.jpg;
blue_hummingbird_2019.jpg;
blue_jay_2019.jpg;
blue_yellow_macaw_2018.jpg;
border_collie_2018.jpg;
butterfly_2020.jpg;
cash_2021.jpg;
cat_2019.jpg;
cat_grey_2019.jpg;
cat_ohio_2019.jpg;
chanel_2020.jpg;
choco_dog_2018.jpg;
cobalt_2022.jpg;
coco_2020.jpg;
dale_and_nessy_2022.jpg;
doberman_2019.jpg;
dog_2019.jpg;
double_cat_portrait_2019.jpg;
dragon_2020.jpg;
dragon_2022.jpg;
duke_2021.jpg;
evie_2021.jpg;
fox_2019.jpg;
french_bulldog_2019.jpg;
goose_and_bird_2021.jpg;
green_macaw_2018.jpg;
green_parrots_2018.jpg;
grey_cat_2018.jpg;
horses_2021.jpg;
hummingbird_2019.jpg;
izzy_2020.jpg;
jaguar_2019.jpg;
joey_2020.jpg;
kalino_2022.jpg;
Kyra_2022.jpg;
lali_2020.jpg;
laz_2019.jpg;
leopard_2020.jpg;
lynx_2019.jpg;
martyna_2020.jpg;
max_2021.jpg;
miracle_2020.jpg;
mojo_2022.jpg;
mowgli_marlo_2020.jpg;
nak_2021.jpg;
nak_2020.jpg;
peacock_2019.jpg;
peanut_2022.jpg;
pika_roki_2020.jpg;
quik_2022.jpg;
racoons_2020.jpg;
rasputin_2021.jpg;
red_macaw_2019.jpg;
red_panda_2021.jpg;
riggs_2019.jpg;
roxy_2021.jpg;
ruby_2021.jpg;
rufus_2021.jpg;
sapphire_2019.jpg;
sasha_2021.jpg;
scarlett_2020.jpg;
silver_2022.jpg;
silver_bells_2019.jpg;
simon_2021.jpg;
squirrel_2020.jpg;
sun_parakeet_2019.jpg;
three_macaws_2020.jpg;
tiger_2019.jpg;
trufo_2019.jpg;
turtle_2020.jpg;
white_cockatoo_2018.jpg;
white_cockatoo_2019.jpg;
wollie_2022.jpg;
yellow_bird_2021.jpg;
yellow_dog_2019.jpg`.split(';')
    return arr;
}