export const text = `double_cat_portrait_2019;Double cat portrait;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
chanel_2020;Chanel;11? X 14? (27.9 cm x 35.6 cm);Pastel pencils and soft pastels
choco_dog_2018;Choco dog;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
french_bulldog_2019;French bulldog;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
cobalt_2022;Cobalt;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
coco_2020;Coco;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
dale_and_nessy_2022;Dale and Nessy;11? x 14?;Colored pencils on paper
doberman_2019;Dobermann;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
border_collie_2018;Border collie;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
dragon_2020;Dragon;9? x 12? (22.9 cm x 30.5 cm);Pastel pencils and soft pastels
dragon_2022;Dragon;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
duke_2021;Duke;18? X� 24?;Colored pencils on paper
evie_2021;Evie;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
fox_2019;Fox;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
goose_and_bird_2021;Goose and a bird;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
green_macaw_2018;Green macaw;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
green_parrots_2018;Green parrots;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
grey_cat_2018;Grey cat;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
sapphire_2019;Sapphire;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
laz_2019;Laz;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
hummingbird_2019;Hummingbird;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
blue_hummingbird_2019;Hummingbird;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
izzy_2020;Izzy;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
jaguar_2019;Jaguar;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
joey_2020;Joey;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
kalino_2022;Kalino;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
Kyra_2022;Kyra;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
lali_2020;Lali;18? x 24?;Colored pencils on paper
leopard_2020;Leopard;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
barbossa_aubrey_2019;Barbossa and Aubrey;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
lynx_2019;Lynx;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
red_macaw_2019;Red macaw;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
martyna_2020;Martyna;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
max_2021;Max;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
miracle_2020;Miracle;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
cat_2019;Cat;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
mojo_2022;Mojo;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
mowgli_marlo_2020;Mowgli and Marlo;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
nak_2020;Nak;9? x 12? (22.9 cm x 30.5 cm);Pastel pencils and soft pastels
nak_2021;Nak;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
dog_2019;Dog;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
peacock_2019;Peacock;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
peanut_2022;Peanut;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
pika_roki_2020;Pika and Roki;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
blue_jay_2019;Blue Jay;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
quik_2022;Quik;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
racoons_2020;Racoons;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
rasputin_2021;Rasputin;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
red_panda_2021;Red panda;50 x 70cm;Pastel pencils and soft pastels
roxy_2021;Roxy;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
ruby_2021;Ruby;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
rufus_2021;Rufus;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
sasha_2021;Sasha;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
scarlett_2020;Scarlett;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
silver_bells_2019;Silver bells;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
simon_2021;Simon;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
squirrel_2020;Squirrel;9? x 12? (22.9 cm x 30.5 cm);Pastel pencils and soft pastels
sun_parakeet_2019;Sun parakeet;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
trufo_2019;Trufo;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
turtle_2020;Turtle;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
tiger_2019;Tiger;11? X 14? (27.9 cm x 35.6 cm);Pastel pencils and soft pastels
white_cockatoo_2018;White cockatoo;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
white_cockatoo_2019;White cockatoo;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
wollie_2022;Wollie;11? x 14? (27.9 cm x 35.6 cm);Colored pencils on paper
yellow_bird_2021;Yellow bird;6? X 8?;Colored pencils on paper
yellow_dog_2019;Yellow dog;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
three_macaws_2020;Three macaws;50 x 70 cm;Colored pencils on paper
angie_2022;Angie;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
silver_2022;Silver;11? x 14?;Colored pencils on paper
cash_2021;Cash;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
horses_2021;Horses;18? x 24?;Colored pencils on paper
astrid_2022;Astrid;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
bailey_2022;Bailey;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
bella_2020;Bella;9? x 12? (22.9 cm x 30.5 cm);Pastel pencils and soft pastels
blue_yellow_macaw_2018;Blue yellow macaw;9? x 12? (22.9 cm x 30.5 cm);Colored pencils on paper
butterfly_2020;Butterfly;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
cat_grey_2019;Grey cat;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
riggs_2019;Riggs;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper
cat_ohio_2019;Cat;11? X 14? (27.9 cm x 35.6 cm);Colored pencils on paper`;

