import React, { Fragment } from 'react';
import ReactDOM from 'react-dom'
import '../../style/css/StylePc.css'
const Home = () => {
    return (
        <Fragment>
            <div id="header" className='headerMobile'>
                <div></div>
                <span className='headerTextMobile'>Blue Birdie Drawings</span>
            </div>

            <div className='parrotBackgroundMobile'>
                <div className='boldTitleMobile'> Colored pencil and pastel animal portraits</div>
                <div className='slimTitleMobile'>Book your custom pet portrait today!</div>
            </div>

            <div style={{marginTop:'50px', marginLeft:'4%', marginRight:'4%', lineHeight:'22px', wordBreak:'break-word', fontSize:'1rem'}}>
                <span style={{color:'#143354', fontWeight:'bold', fontSize:'1.4rem'}}>About my work</span>
                <br></br><br></br>
                <span>My name is Roksana and I’m a traditional artist working with different mediums including colored pencils and pastels. I create fine art portraits of a wide range of domestic animals and wildlife. My work is easily recognizable and valued by customers around the world.
                </span>
                <br></br><br></br>
                <span>
                I specialize in creating custom animal drawings. A custom handmade pet portrait is created based on a reference photo provided by a client. Using archival quality paper and only finest quality materials ensures longevity of the commission. Each piece is made with attention to details and color accuracy which makes it unique.
                </span>
                <br></br><br></br>
                <span>
                If you’re interested in your own custom pet portrait please read the section below “How to order” and the “Commission” tab where you can find all the important information about the ordering process.
                </span>
            </div>

            <div style={{marginTop:'30px', display:'flex', maxWidth:'100vw', width:'100vw', marginLeft:'4%', marginRight:'4%'}}>
                <div style={{display:'flex', flexDirection:'column', width:'29%',gap:'10px'}}>
                    <img style={{width:'90%'}} src={require('../../img/Home/nak_closeup1.png')}></img>
                    <img style={{width:'90%'}} src={require('../../img/Home/nak_closeup2.png')}></img>
                    <img style={{width:'90%'}} src={require('../../img/Home/nak_closeup3.png')}></img>
                </div>

                <div style={{width:'63%'}}>
                    <img style={{width:'100%'}} src={require('../../img/Home/nak.png')}></img>
                </div>
            </div>

            <div style={{marginTop:'50px',marginLeft:'4%', marginRight:'4%', lineHeight:'22px', wordBreak:'break-word', fontSize:'1rem'}}>
                <span style={{color:'#143354', fontWeight:'bold', fontSize:'1.4rem'}}>How to order</span>
                
                <div className='orderBackgroundMobile'>
                    <span style={{color:'#143354', fontWeight:'bold', fontSize:'1.5rem'}}>1. Contact me</span>
                    <span>First, send me a message via contact form on the website, through my social media or directly to my e-mail <strong>bluebirdiedrawings@gmail.com</strong>. We will discuss details such as starting date, special requests, composition, background, time of drawing, shipping costs etc.</span>
                    <br></br>
                    <span style={{color:'#143354', fontWeight:'bold', fontSize:'1.5rem'}}>2. Reference photos</span>
                    <span>Choose a photograph which shows a lot of details, isn't blurred or too dark and shows your pet's personality. High quality photos guarantee realistic and detailed portraits.</span>
                    <span>If you can't decide on the reference photo send couple of them and we will choose the best one together.</span>
                    <br></br>
                    <span style={{color:'#143354', fontWeight:'bold', fontSize:'1.5rem'}}>3. Size of the drawing</span>
                    <span>Decide on the size of the portrait – I offer a number of different sizes. Smaller sizes like 9"x12" are perfect for a head & neck drawing. If you'd like two or more pets in one drawing 11" x 14" and bigger sizes are perfect for that.</span>
                    <span>If you have a particular size in mind, please get in touch.</span>
                    <br></br>
                    <span style={{color:'#143354', fontWeight:'bold', fontSize:'1.5rem'}}>4. Payment</span>
                    <span>After accepting your photos, an initial payment is required - currently 30%. Initial payment covers the cost of materials and secures your booking.
                    This payment is non-refundable, except in the unfortunate event that I have to cancel your commission.</span>
                    <span>I accept payments via PayPal or bank transfer.</span>
                    <br></br>
                    <div className='bookButtonMobile'>Book your portrait</div>
                </div>

            </div>

            <div style={{marginTop:'30px',marginLeft:'4%', marginRight:'4%', lineHeight:'22px', wordBreak:'break-word', fontSize:'1rem', marginBottom:'30px'}}>
                <img style={{width:'100%'}} src={require('../../img/Home/dragon.png')}></img>
            </div>
        </Fragment>
    )
}

export default Home;