export const SILVER = ['Silver', `Colored pencils\non 11" x 14" toned paper`, 'The quality of the drawing was phenomenal, and it was finished in just a month!', 'Matt - Kentucky, USA']
export const CASH = ['Cash', 'Colored pencils\non 11" x 14" toned paper', `Roksana was great to work with. We had limited pictures because Cash had passed away but she was really helpful in finding the right things to look for. Her work is absolutely amazing and such a great tribute to our beloved dog. Thank you!`, `Robbert - Idaho, USA`]
export const COCO = ['Coco\nPika and Rocki\nTrufo', 'Colored pencils\non 11" x 14" toned paper',`Choosing a gift for someone (even yourself!) can be something really special. Nowadays we’re constantly replacing stuff like clothes, technology, etcetera. Everything becomes obsolete in a few years. Everything with the exception of art, even more so when it’s a beautiful custom portrait of your beloved pet, and I think Roksana is the perfect person to commission for that.

The amount of detail, hard work, constant updates on the progress of each portrait, and willingness to understand my vision for each drawing, was astounding. My order arrived to Spain swiftly after Roksana posted it, in the sturdiest cardboard boxes and bindings and wrapped carefully in a special paper to prevent any smudging at all. Even the smallest details were taken care of. I absolutely recommend her work and I’ll be looking forward to commissioning her again for sure!`,`Kony Pyon - Spain`]
export const DUKE = ['Duke', 'Colored pencils\non 18" x 24" toned paper',`Overall I am blown away by the level of detail in this drawing! I had high hopes for this after seeing some of your work online and this exceeded my expectations! If I ever need another portrait, I will definitely be going back to you! Thank you so much!!`,`Zach - Colorado, USA`]
export const GOOSE = ['Goose & bird', 'Colored pencils\non 9" x 12" toned paper',`Roksana was very professional and attentive. She responded quickly to my request. She made sure to keep me posted with updates on her progress and was open to any suggestions or changes. The artwork speaks for itself. Pictures do not do it any justice. Every last detail was done with care, including delivery. The drawing arrived in a beautiful package with a certificate of authenticity. I wish this brilliant artist the best of luck. I will cherish her work forever.`, `Minu - North Carolina, USA`]
export const HORSES = ['Horses', 'Colored pencils\non 18" x 24" toned paper',`This is the second comission I have gotten off Roksana, always very happy with her work and looks amazing. Some of the pictures I have sent have been of poor quality but she always manage to make it look amazing in the final drawing with the depth of detail.

Will definitely be asking again for another submission if she has
a slot.`, `Rowena - UK`]
export const IZZY = ['Izzy', 'Colored pencils\non 11" x 14" toned paper', `Roksana was such a pleasure to work with through the entire process.

She was very responsive, patient and polite while finding the right picture. She provided consistent updates and the picture arrived very securely packaged and without issue. I would recommend to anyone looking for photorealistic drawings of your furry family members!`, `Cody - Colorado, USA`]
export const KALINO = ['Kalino', 'Colored pencils\non 9" x 12" toned paper', `I absolutely adore the piece. You captured my baby boy perfectly, from the shape of his irises to his fat bottom lip. It was packaged with care and came wrapped in a bow which made me smile. I love it so much, it is going to forever be my prized possession.
Thank you so much Roksana. I will definitely be contacting you in the future for commissions of my other babies.`, `Meghan - Texas, USA`]
export const LALI = ['Lali', 'Colored pencils\non 18" x 24" toned paper', `From my initial inquiry on a commission to when the perfectly packaged portrait showed up at my door, I was continually impressed with Roksana.

The frequent updates were a joy to watch on Instagram, and the finished product far exceeded my expectations. My only regret is that she's now booked through the end of the next year and I have a second dog that's jealous he doesn't yet have a portrait by Blue Birdie Drawings!`, `Joshua - Georgia, USA`]
export const MIRACLE = ['Miracle;Joey', 'Colored pencils\non 9" x 12" toned paper;Colored pencils\non 11" x 14" toned paper', `Roksana drew two pictures for me. Even though I loved her artistry I was hesitant because it would need to be mailed to the U.S.
​
Well, I went forward anyway and I am so glad I did! The pictures, one was a gift, turned out beautifully and they arrived packaged perfectly with a Certificate of Authenticity, signed by the artist. Roksana kept me posted on the progress of the drawing and even contacted me when the drawings arrived to confirm I received them. That was before I even knew the package was on my porch! Anyway, she is a perfectionist and talented artist. I highly recommend her for your drawing.`, `L.M. - California USA`]
export const RASPUTIN = ['Rasputin', 'Colored pencils\non 9" x 12" toned paper', `I was absolutely astounded with the entire process with bluebirdiedrawings, from the beginning sketch to the final drawing and shipping, everything was so much faster then expected. You honestly cannot tell the difference between a photo of my dog and the portrait. Thank you so much`, `Matthew - Canada`]
export const RUBY = ['Ruby', 'Colored pencils\non 11" x 14" toned paper', `Absolutely impeccable service. Roksana provided updates along the way, and ensured we were happy with the result. We received our portrait today and it was better than I could have imagined. Ruby looked so life like. Plus, it was beautifully packaged.We are so grateful.`, `Kristal - Australia`]
export const RUFUS = ['Rufus', 'Colored pencils\non 9" x 12" toned paper', `The drawing you’ve made is absolutely amazing! It’s really impressive the amount of detail you can see in every pencil stroke, rendering the portrait very realistic. All in all, I’m very happy with your service as well. You were always professional, kind, and sent me regular updates of the drawing. I wish you good luck with your future endeavors and Rufus thanks you as well.`, `Timofey - Netherlands`]