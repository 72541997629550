import React, { useEffect, useState } from 'react';
import MainPC from './components/pc/Main';
import MainMobile from './components/mobile/Main';
import { detectAgent } from './AgentDetector';
import './App.css'
const App = () => {
  const [agent, setAgent] = useState(detectAgent())
  const [width, setWidth] = useState(window.innerWidth);
  
  window.addEventListener('resize', () => setWidth(window.innerWidth))

  if(!agent && width >=1000) {
    return (
      <MainPC />
    )
  } else { 
      return (
          <MainMobile />
      )
  }
}

export default App;
