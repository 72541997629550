import React, { Fragment, useState, useRef } from 'react';
import '../../style/css/StylePc.css';
import Pricing from './Pricing';
const Comissions = () => {

    return (
        <>
            <div id="topCommissions" className='topbarCommissions'>
                <div id="comissionsMainText" className='commissionsMainText'>
                    <span style={{fontSize:'2.5rem', fontWeight:'700'}}>Comissions</span>
                    <span style={{fontSize:'1.5rem', marginBottom:'1rem'}}>Sized and prices</span>
                    <span>This website includes sizes and prices of my commissions.
                    I create my art using two techniques: colored pencils and pastels. When ordering please let me know if a specific technique is required. 
                    </span>
                </div>
            </div>

            <div className='commissionsContainer'>
                <div className='leftPartCommissions'>
                    <div style={{color:'#143354', fontSize:'2rem', fontWeight:'700'}}>Colored pencil drawing</div>
                    <div>Portrait made with this technique is very detailed but high quality reference photo is required to achieve realism. Art process takes more time than with pastels and depends on the size of the drawing. </div>
                    <div style={{display:'flex', gap:'25px', marginTop:'2rem'}}>
                        <Pricing sizeIn={'9” x 12”'} sizeCm={'22,9 cm x 30,5 cm'} text={'Size suitable for head & neck portrait'} price={'$300*'}/>
                        <Pricing sizeIn={'11” x 14”'} sizeCm={'27,9 cm x 35,6 cm'} text={'Size suitable for head & neck or full body'} price={'$350*'}/>
                        <Pricing sizeIn={'18” x 24”'} sizeCm={'45,7 cm x 61 cm'} text={'Size suitable for full body or multiple subjects'} price={'$480*'}/>
                    </div>
                    <div style={{marginTop:'2rem'}}>*Prices do not include shipping costs.</div>
                    <div style={{marginTop:'2rem'}}>I ship worldwide, please contact me and provide your location for checking estimated shipping costs to your country. Shipping from Europe!</div>
                    <div style={{marginTop:'2rem'}}>It is possible to order your portrait in any size
and it can be made using white or toned paper.
All backgrounds will be left white or in color of the paper unless requested otherwise.</div>
                </div>

                <div className='rightPartCommissions'>
                    <img alt="Cat picture" style={{width:'100%'}} src={require('../../img/Commissions/commissions_colored_pencils.png')}></img>
                    <div style={{display:'flex', marginTop:'15px', justifyContent:'space-between'}}>
                        <img alt="Cat picture" style={{width:'30%', height:'30%'}} src={require('../../img/Commissions/commissions_colored_pencils_closeup1.png')}></img>
                        <img alt="Cat picture" style={{width:'30%', height:'30%'}} src={require('../../img/Commissions/commissions_colored_pencils_closeup2.png')}></img>
                        <img alt="Cat picture" style={{width:'30%', height:'30%'}} src={require('../../img/Commissions/commissions_colored_pencils_closeup3.png')}></img>
                    </div>
                </div>
            </div>

            <div className='commissionsContainer'>
                <div className='leftPartCommissions'>
                    <div style={{color:'#143354', fontSize:'2rem', fontWeight:'700'}}>Pastel drawing</div>
                    <div>Portrait made with this technique looks almost like a painting but you can’t see every detail. Fur and feathers look smooth and some colors are more saturated. Art process is much faster than with colored pencils but it also depends on the size of your drawing.</div>
                    <div style={{display:'flex', gap:'25px', marginTop:'2rem'}}>
                        <Pricing sizeIn={'9” x 12”'} sizeCm={'22,9 cm x 30,5 cm'} text={'Size suitable for head & neck portrait'} price={'$300*'} availability={false}/>
                        <Pricing sizeIn={'11” x 14”'} sizeCm={'27,9 cm x 35,6 cm'} text={'Size suitable for head & neck or full body'} price={'$350*'} availability={false}/>
                        <Pricing sizeIn={'18” x 24”'} sizeCm={'45,7 cm x 61 cm'} text={'Size suitable for full body or multiple subjects'} price={'$480*'} availability={false}/>
                    </div>
                    <div style={{marginTop:'2rem'}}>*Prices do not include shipping costs.</div>
                    <div style={{marginTop:'2rem'}}>I ship worldwide, please contact me and provide your location for checking estimated shipping costs to your country. Shipping from Europe!</div>
                    <div style={{marginTop:'2rem'}}>It is possible to order your portrait in any size
and it can be made using white or toned paper.
All backgrounds will be left white or in color of the paper unless requested otherwise.</div>
                </div>

                <div className='rightPartCommissions'>
                    <img alt="Cat picture" style={{width:'100%'}} src={require('../../img/Commissions/commissions_pastels.png')}></img>
                    <div style={{display:'flex', marginTop:'15px', justifyContent:'space-between'}}>
                        <img alt="Cat picture" style={{width:'30%', height:'30%'}} src={require('../../img/Commissions/commissions_pastels_closeup1.png')}></img>
                        <img alt="Cat picture" style={{width:'30%', height:'30%'}} src={require('../../img/Commissions/commissions_pastels_closeup2.png')}></img>
                        <img alt="Cat picture" style={{width:'30%', height:'30%'}} src={require('../../img/Commissions/commissions_pastels_closeup3.png')}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Comissions;