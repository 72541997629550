import React, { Fragment, useState, useRef, useEffect } from 'react';
import '../../style/css/StylePc.css';
import { list } from '../list';
import { text } from '../rys_opisy';
const Gallery = () => {
    const arr = [];
    list().forEach(value => arr.push(value.replace('\n', '')));

    const itemsArr = text.split('\n')
    const descArr = []
    itemsArr.map(el => {
        descArr[el.substring(0, el.indexOf(';'))] = el.substring(el.indexOf(';') + 1)
    })
    return (
        <>
            <div id="topCommissions" className='topbarGallery'>
                <div id="comissionsMainText" className='commissionsMainText'>
                    <span style={{ fontSize: '2.5rem', fontWeight: '700' }}>Gallery</span>
                    <span style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Commissions & personal projects</span>
                    <span>Gallery includes artwork in various sizes and techniques showing a large number of pets and wildlife animals. Presented pieces were made by hand and the oldest one is from 2016.
                    </span>
                </div>
            </div>

            <div id="gallery" className='gallery'>
                {arr.map(el => {
                    const miniArr = descArr[el.replace('.jpg', '')].split(';');
                    console.log(miniArr)
                    return (
                        <div key={el} className="photoContainer">
                            <img alt={el} style={{ maxWidth: '100%' }} src={require(`../../img/Gallery/bbd_gallery/${el}`)}></img>
                            <div className="photoTextContainer">
                                <div className="photoText">
                                    <p>{miniArr[0]}</p>
                                    <p className='lowerText'>{miniArr[1].replace(new RegExp("\\?", "g"),'\'\'')}</p>
                                    <p className='lowerText'>{miniArr[2]}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </>
    );
}

export default Gallery;