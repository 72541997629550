import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom'
import '../../style/css/StyleMobile.css'
import Home from './Home';
const Main = () => {
    const [currentTab, setCurrentTab] = useState('Home')
    const tabs = ['Home']
    // const tabs = ['Home', 'About me', 'Commisions', 'Reviews', 'Gallery', 'Get in touch']; 

    const changeTab = (tab) => {
        setCurrentTab(tab);
        
    }
    return (
        <Fragment>
            <div id="navbarMobile">

            </div>

            {currentTab === 'Home' ? <Home />:null}

            <div className='footerMobile'>
                <div style={{display:'flex', gap:'10px', alignItems:'center'}}><div className='iconBoxMobile'><img style={{width:'12px'}} src={require('../../img/Home/gmail.png')}></img></div><span>bluebirdiedrawings@gmail.com</span></div>
                <div style={{display:'flex', gap:'10px', alignItems:'center'}}><div className='iconBoxMobile'><img style={{width:'12px'}} src={require('../../img/Home/ig.png')}></img></div><span>/BlueBirdieDrawings</span></div>
                <div style={{display:'flex', gap:'10px', alignItems:'center'}}><div className='iconBoxMobile'><img style={{width:'14px'}} src={require('../../img/Home/fb.png')}></img></div><span>/BlueBirdieDrawings</span></div>
                <div style={{display:'flex', gap:'10px', alignItems:'center'}}><div className='iconBoxMobile'><img style={{width:'14px'}} src={require('../../img/Home/tt.png')}></img></div><span>/BlueBirdieDrawings</span></div>
            </div>
        </Fragment>
    )
}

export default Main;